import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/indexPage/index.vue'),
        aalias: '/'
    },
    {
        path: '/pulish',
        name: 'pulish',
        component: () => import('@/views/pulishPage/index.vue')
    },
    {
        path: '/entcom',
        name: 'entcom',
        component: () => import('@/views/entcomList/index.vue')
    },
    {
        path: '/entcompy',
        name: 'entcompy',
        component: () => import('@/views/entcomList/comListPage.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/loginPage/index.vue')
    },
    {
        path: '/detail',
        name: 'detail',
        component: () => import('@/views/detailPage/index.vue')
    },
    {
        path: '/header',
        name: 'header',
        component: () => import('@/components/headerCom/index.vue')
    },
    {
        path: '/footer',
        name: 'heafooterder',
        component: () => import('@/components/footerCom/index.vue')
    },
    // 404页面
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: () => import('@/views/noFound/404Page.vue'),
    },
]


const router = new VueRouter({
    mode: 'history',
    routes,
})

export default router
