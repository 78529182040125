import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isLogin: false,
        userInfo: {},
        token: null
    },
    getters: {},
    mutations: {
        setUserInfo(state, data) {
            console.log(state, data)
        },
        changeisLogin(state, data) {
            // console.log(data, '登录状态')
            if (data == 200) {
                state.isLogin = true
                localStorage.setItem('isLogin', state.isLogin)
            } else {
                this.state.isLogin = false
                localStorage.setItem('isLogin', state.isLogin)
            }
        },
        changeUserInfo(state, data) {
            localStorage.setItem('userInfo', data)
        },
        settingToken(state, data) {
            // 存token
            // console.log(data, 'vuex')
            state.token = data
            localStorage.setItem('token', state.token)
        }
    },
    actions: {},
    modules: {}
})
